import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="About"
        keywords={[
          "marketing",
          "mereo",
          "development",
          "software",
          "advertising",
          "strategy",
          "agency",
          "about",
        ]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2>
            Mereo Marketing helps businesses of all sizes unlock their digital
            potential with expert web design, development, SEO, and marketing
            strategies. Our dedicated team enhances client efficiency and drives
            revenue growth.
          </h2>
          <figure className="kg-card kg-image-card kg-width-full">
            <Img
              fluid={data.benchAccounting.childImageSharp.fluid}
              className="kg-image"
            />
          </figure>
          <h3 id="dynamic-styles">Meet The Team</h3>
          <p>
            <strong>Cameron Young - Head of Technology</strong>
            <br />
            Cameron is the tech visionary behind Mereo Marketing, specializing
            in full stack development and innovative software solutions. With a
            passion for leveraging the latest technologies, Cameron ensures that
            our clients receive cutting-edge digital solutions tailored to their
            unique needs.
          </p>
          <p>
            <strong>George Brereton - Head of Creative</strong>
            <br />
            George leads the creative charge at Mereo Marketing, bringing a
            unique blend of design expertise and artistic flair to every
            project. His keen eye for aesthetics and dedication to brand
            storytelling help transform ideas into compelling visual experiences
            that resonate with audiences.
          </p>
          <p>
            <strong>James Sides - Head of Strategy</strong>
            <br />
            James is the strategic mastermind at Mereo Marketing, responsible
            for crafting comprehensive marketing strategies that drive growth
            and engagement. His analytical skills and deep understanding of
            market dynamics enable our clients to navigate the competitive
            landscape and achieve their business goals.
          </p>
          <h3 id="dynamic-styles">What we offer</h3>
          <p>
            Mereo Marketing offers a wide range of services designed to help businesses thrive online. Our expertise includes web design and development, tailored software solutions, and effective SEO strategies. We also specialize in managing social media and creating comprehensive marketing strategies to boost your brand's visibility and engagement. Our goal is to provide practical, high-quality digital experiences that are both aesthetically pleasing and functionally robust. Whether you need a new website, want to enhance your online presence, or require custom software, our team is here to support your success.
          </p>
          <p>
            Our approach is tailored to the unique needs of each client, ensuring that every solution aligns with your business objectives. We begin by understanding your vision and goals, then develop strategies that drive growth and enhance your brand's online presence. From optimizing search engine rankings to crafting engaging social media campaigns, Mereo Marketing is your reliable partner in achieving measurable results. We combine technical expertise with creative insights to help your business stand out and succeed.
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(relativePath: { eq: "abstract-mereo-cover.webp" }) {
      childImageSharp {
        fluid(maxWidth: 2250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
